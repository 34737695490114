type Env = "local" | "ngrok" | "staging" | "rc" | "production";

export default function getEnv(): Env {
    const { host } = window.location;
    if (
        host.includes("play.getvolley") ||
        host.includes("app.getvolley") ||
        host.includes("play.onevolley") ||
        host.includes("app.onevolley")
    ) {
        return "production";
    }

    if (
        host.includes("staging.getvolley") ||
        host.includes("staging.onevolley")
    ) {
        return "staging";
    }

    if (host.includes("ngrok.app")) {
        return "ngrok";
    }

    if (host.includes("rc.getvolley") || host.includes("rc.onevolley")) {
        return "rc";
    }

    return "local";
}
